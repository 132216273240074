<div class="error-wrap">
  <div class="error-code">{{ code }}</div>
  @if (title) {
    <div class="error-title">{{ title }}</div>
  }
  @if (message) {
    <div class="error-message">{{ message }}</div>
  }
  <div><a mat-raised-button color="primary" routerLink="/">Back to Home</a></div>
</div>
