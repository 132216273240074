import { Component, ViewEncapsulation, Input } from '@angular/core'
import { RouterLink } from '@angular/router'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'app-error-code',
  templateUrl: './error-code.component.html',
  styleUrls: ['./error-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatButtonModule, RouterLink],
})
export class ErrorCodeComponent {
  @Input() code = ''
  @Input() title = ''
  @Input() message = ''
}
